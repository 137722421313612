import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <BrowserView>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </BrowserView>
        <MobileView>
          <DndProvider backend={TouchBackend}>
            <App />
          </DndProvider>
        </MobileView>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
