import { HighlightOffOutlined } from "@mui/icons-material";

export default function CommentCard(props){

    // const props =  {
    //     id:1,
    //     rating: 4,
    //     sex: 0,
    //     age: 2,
    //     type: "エアコン",
    //     date: "2024.02.11",
    //     comment: "AAAAAAAA AA  AA AA  aAAAAAAAAAA AAAAA  aAAAAAAAAAA AAAAA AA  aAAAAAAAAAA AAAAA aAAAAAAAAAA AAAAA aAAAAAAAAAAAA AAAAAAAAAAA "
    // };

    const cardStyle = {
        backgroundColor: "white",
        margin:"10px",
        border:"solid 1px #b1b1b1",
        position:"relative",
        display:"inline-block",
        width:"260px",
        height:"200px"
    };

    const deleteIcon = {
        position:"absolute",
        top:"5px",
        right:"5px",
        cursor:"pointer",
    };

    const inputsStyle = {
        minWidth:"260px",
        display: "block"
    };    

    const topContent = {
        display: "inline-flex",
        paddingLeft:"7px",
        paddingTop:"5px",
        alignItems:"center"
    };    

    const iconStyle={
        fontSize:"40px"
    };

    const starIcon ={
        width:"20px",
        height:"20px"
    };

    const topLeftText = {
        width:"35px",
        display:"inline-block"
    };

    const topRightText = {
        width:"100px",
        display:"inline-block",
        textAlign:"right",

    };
    const ratingStyle = {
        display:"inline-block",
        marginTop:"4px",
    };

    const typeStyle ={
        border:"solid 2px gray",
        borderRadius:"4px",
        padding:"3px"
    };

    const commentStyle = {
        paddingLeft:"20px",
        paddingTop:"10px",
        paddingRight:"20px",
        paddingBottom:"10px",
        height:"80px",
        overflow: "auto",
    };

    const dateStyle = {
        color:"gray",
        textAlign:"right",
        fontSize:"12px"
    };

    const bottomContent = {
        borderTop: "solid 1px gray",
        marginTop: "5px",
        marginLeft: "10px",
        marginRight: "10px",
    };

    const starGold = "star_gold.svg";
    const starGray = "star_gray.svg";
    
    return(
        <div style={cardStyle}>
            <div style={inputsStyle}>
               <div style={topContent}>
                    <div className="grayFont"  style={topLeftText}>評価</div>
                    <div style={ratingStyle}>
                        <img style={starIcon} src={props.rating > 0 ? starGold : starGray} />
                        <img style={starIcon} src={props.rating > 1 ? starGold : starGray} />
                        <img style={starIcon} src={props.rating > 2 ? starGold : starGray} />
                        <img style={starIcon} src={props.rating > 3 ? starGold : starGray} />
                        <img style={starIcon} src={props.rating > 4 ? starGold : starGray} />
                    </div>
                    <div className="grayFont" style={topRightText}>
                        {props.sex === 0 ? "女性" : "男性"} • {props.age + "0代"}
                    </div>
                </div> 
               <div style={topContent}>
                    <div className="grayFont" style={typeStyle}>{props.type}</div>
                </div> 
                <div className="grayFont" style={commentStyle}>
                    {props.comment}
                </div>
                <div  style={bottomContent}>
                   <div style={dateStyle}>  {props.date}</div>
                </div>
            </div>
        </div>
    )
}