export const apiurl = process.env.REACT_APP_WEB_API_URL;
export const imagesUrl = apiurl + "images/";
export const generalPath = "generalinfo";
export const commentsPath = "comments";
export const examplesPath = "examples";
export const pricesPath = "prices";
export const questionsPath = "questions";
export const imageUpload = "uploadimage";
export const imagesListPath = "imageslist";
export const deleteimagePath = "deleteimage";
export const loginPath = "login";
export const checkAuthPath = "checkauth";
export const requestBody = (body) => {
    return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") },
        body: JSON.stringify(body)
    };
}