import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import {CardDAD} from './dCard'
const style = {
  width: "auto",
}
export const Container = (props)=> {
  {
    const elemPositions = [
        {
          id: 1,
          text: 'メニュー',
          value:"menu"
        },
        {
          id: 2,
          text: 'ロコミ',
          value:"comments"
        },
        {
          id: 3,
          text: 'サービス',
          value:"services"
        },
        {
          id: 4,
          text: 'よくある質問',
          value:"questions"
        },
      ];
    const [cards, setCards] = useState([])
    
    useEffect(
        () => {
            props.setOrderOfContent(cards.map(a => a.value));
         }
        ,[cards])

    useEffect(
        () => {
            var order = props.order;
            var newArray = [];
            for(var a in order){
                var newelem = elemPositions.find(x=>x.value==order[a]);
                newelem.id = a+1;
                newArray.push(newelem);
            }
            setCards(newArray);
         }
        ,[])

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])
    const renderCard = useCallback((card, index) => {
      return (
        <CardDAD
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      )
    }, [])
    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}
