import { TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import { CardDADContainer } from "./card_container";

export default function Example(){
    
    const examples = [{
        id:0,
        image: "",
        text:""
    }];
    
    const [examplesState, setExamplesState] = useState(examples);
    const [changed, setChanged] = useState(false);
    const [examplesStateWithHtml, setExamplesStateWithHtml] = useState(examples);

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
   
    const [selectedImage, setSelectedImage] = useState("");

    const deleteExample = (id) =>{
        const newExamples = examplesState.filter((obj) => obj.id !== id);
        setExamplesState(newExamples);
        setExamplesStateWithHtml(newExamples);
    };
    
    const addExample = () =>{
        let newid = 0;
        if(examplesState !== undefined && examplesState.length > 0)
            newid =  Math.max(...examplesState.map(o => o.id))+ 1;
        const newElement = {id:newid, image:"",text:""};
        var data = [...examplesState, newElement];
        setExamplesState(data);        
        setExamplesStateWithHtml(data);
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        var data = ChangeArray(examplesState, id, name, value);
        setExamplesState(data);     
        setHtmlForOrder(data);   
    };

    function setHtmlForOrder(data){
        var newArr = [];
        for(var a in data){
            var newObj = {};
            newObj.id = a+1;
            newObj.mainid = data[a].id;
            newObj.html = <>
            <div className="order-card-content">
                <img src={data[a].image}/>
                <div>{data[a].text}</div>
            </div>
            </>;
            newArr.push(newObj);
        }
        setChanged(!changed);
        setExamplesStateWithHtml(newArr);
    }

    function setOrderOfContent(data){
        var newArr = [];
        if(data == null || data == undefined || data.length == 0)
            return;
        if(examplesState == null || examplesState == undefined || examplesState.length == 0)
            return;        
        console.log(data);
        for(var a in data){
            var newObj = {};
            newObj = examplesState.find(x=>x.id==data[a])
            newArr.push(newObj);
        }
        setExamplesState(newArr);
    }

    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var e = {
                target:{
                    id:uploadPropertyState.id,
                    name:uploadPropertyState.name,
                    value:filename
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const selectImage = (e) => {
        const newE = e.target;
        setSelectedImage(e.target.value);
        setUploadPropertyState(newE);
        setUploaderActiveState(true);
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.examplesPath)
        .then((response) => response.json())
        .then((data) => {
           setExamplesState(data);
           setHtmlForOrder(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = () => {
        const body = Constants.requestBody(examplesState);
        fetch(Constants.apiurl + Constants.examplesPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => setExamplesState(data))
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const inputsHtml = (id,image,text) => {
        return(
            <>
                <div className="input-block">
                    <div className="input-title">画像:</div>
                    <input title={image} id={id} style={{"cursor":"pointer"}} onClick={selectImage} name="image" readOnly label="Image Link" variant="outlined" value={image} />
                </div>
                <div className="input-block">
                    <div className="input-title">本文:</div>
                    <input id={id}  onChange={onChangeValue} name="text" label="Text" variant="outlined" value={text} />
                </div>
            </>
        )
    };

    const exapmlesHtml = examplesState.map(item=> 
            <Card key={item.id + item.image} child={inputsHtml(item.id,item.image,item.text)} id={item.id} handleDelete={deleteExample} />
        )


    return(
        <>
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addExample} />
            </div>
            {exapmlesHtml}
            <div>
                <CardDADContainer key={"cardcontainer" + changed} setOrderOfContent={setOrderOfContent} data={examplesStateWithHtml}/>
            </div>
        </>
    )
}