import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import * as Constants from "../config";
import { CardDADContainer } from "./card_container";
import CommentCard from "../main components/commentcard";


export default function Feedback(){
    
    const data =  [{
        id:"",
        rating: "",
        sex: 0,
        age: 0,
        type: "",
        date: "",
        comment: ""
    }];

    const [changed, setChanged] = useState(false);
    const [dataState, setDataState] = useState(data);
    const [dataStateHtml, setdataStateHtml] = useState(data);

    const deleteData= (id) =>{
        const newData = dataState.filter((obj) => obj.id !== id);
        setDataState(newData); 
        setHtmlForOrder(newData);   
    };
    
    const addData = () =>{
        let newid = 0;
        if(dataState !== undefined && dataState.length > 0)
            newid =  Math.max(...dataState.map(o => o.id))+ 1;
        const newElement = {id: newid};
        setDataState([...dataState, newElement]); 
        setHtmlForOrder([...dataState, newElement]);   
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        var data = ChangeArray(dataState, id, name, value);
        setDataState(data);  
        setHtmlForOrder(data);   
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.commentsPath)
        .then((response) => response.json())
        .then((data) => {
           setDataState(data);           
           setHtmlForOrder(data);         
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = () => {
        const body = Constants.requestBody(dataState);
        fetch(Constants.apiurl + Constants.commentsPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => setDataState(data))
        .catch(err => {
            console.log(err.error);
        });
    }
    function setHtmlForOrder(data){
        var newArr = [];
        for(var a in data){
            var item = data[a];
            var newObj = {};
            newObj.id = a+1;
            newObj.mainid = data[a].id;
            newObj.html = <>
            <div className="order-card-content-comments">
            <CommentCard 
                    key={item.id+item.rating}
                    rating={item.rating} 
                    age={item.age} 
                    sex={item.sex} 
                    type={item.type} 
                    date={item.date} 
                    comment={item.comment} 
                />
            </div>
            </>;
            newArr.push(newObj);
        }        
        setChanged(!changed);    
        setdataStateHtml(newArr);
    }

    function setOrderOfContent(data){
        var newArr = [];
        if(data == null || data == undefined || data.length == 0)
            return;
        if(dataState == null || dataState == undefined || dataState.length == 0)
            return;        
        console.log(data);
        for(var a in data){
            var newObj = {};
            newObj = dataState.find(x=>x.id==data[a])
            newArr.push(newObj);
        }
        setDataState(newArr);
    }

    useEffect(() => {
        getData();
     }, []);

    const inputsHtml = (id,rating,sex,age,date,type,comment) => {
        return(
            <>
                <div className="input-block">
                    <div className="input-title">レート:</div>
                    <select name="rating" value={rating} id={id} onChange={onChangeValue}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
               </div>
                <div className="input-block">                    
                    <div className="input-title">性別:</div>
                    <select name="sex" value={sex} id={id} onChange={onChangeValue}>
                        <option value="0">女性</option>
                        <option value="１">男性</option>
                    </select>
               </div>
                <div className="input-block">                    
                    <div className="input-title">年代:</div>
                    <select  name="age" value={age} id={id} onChange={onChangeValue}>
                        <option value="1">10代</option>
                        <option value="2">20代</option>
                        <option value="3">30代</option>
                        <option value="4">40代</option>
                        <option value="5">50代</option>
                        <option value="6">60代</option>
                        <option value="7">70代</option>
                        <option value="8">80代</option>
                        <option value="9">90代</option>
                        <option value="10">100代</option>
                    </select>
               </div>
                <div className="input-block">
                    <div className="input-title">ご利用メニュー:</div>
                    <input id={id} value={type}  onChange={onChangeValue} name="type" label="Type" variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">日付:</div>    
                    <input id={id} value={date}  onChange={onChangeValue} name="date" label="Date" variant="outlined" />
               </div>
                <div className="input-block">
                    <div className="input-title">コメント:</div>
                    <textarea id={id} value={comment} onChange={onChangeValue} name="comment" label="Comment" variant="outlined" />
                </div>
            </>
        )
    };

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + item.rating} child={
                inputsHtml(item.id,
                item.rating,
                item.sex,
                item.age,
                item.date,
                item.type,
                item.comment)} 
                id={item.id} handleDelete={deleteData} />
        )

    return(
        <>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData}/>
            </div>
            <div className="cardscontainer">
                {dataHtml}
            </div>
            <div>
                <CardDADContainer key={"cardcontainer" + changed} setOrderOfContent={setOrderOfContent} data={dataStateHtml}/>
            </div>
        </>
    )
}