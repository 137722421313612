import Example from "../admin components/example";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../tools/window_resolution";
import AccordionBlock from "../admin components/accordion_block";
import Price from "../admin components/price";
import Feedback from "../admin components/feedback";
import "../styles/admin.scss";
import Faq from "../admin components/faq";
import GeneralInfo from "../admin components/general_info";
import ImageUploader from "../admin components/imageuploader";

import * as Constants from "../config";
import Login from "./login";

export default function Admin(){

    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;
    const [auth,setAuth] = useState(false);

    const layoutStyle = {
        paddingTop:"20px",
        display:"flex",
        justifyContent:"center",
    };

    const contentStyle = {
        width: screenwidth > 1000 ? "1000px" : screenwidth - 20,
        marginBottom:"300px"
    };

    const pageStyle = {
        backgroundColor:"#818181",        
        width: screenwidth,
        height: screenheight,
        position:"fixed",
        zIndex: "-2"
    };

    const examplesContent = (
        <Example/>);

    const pricesContent = (
        <Price/>);

    const feedbackContent = (
        <Feedback/>);

    const generalInfo = (
        <GeneralInfo/>);

    const faqContent = (
        <Faq/>);

    useEffect(() => {
        checkLogin();
        }, []);

    const checkLogin = () => {
        const token = localStorage.getItem("token");
        fetch(Constants.apiurl + Constants.checkAuthPath, { headers: {"Authorization" : token} })
        .then((response) => {
            if( response.status != 401)
                setAuth(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const loginCompleted =()=>{
        setAuth(true);
    };
    
    return(
        <>

            <div style={pageStyle}>            
            </div>            
            <div style={layoutStyle}>
                <div style={contentStyle}>           
                    <div style={{display:!auth ?"block":"none"}}> <Login loginCompleted={loginCompleted}/></div>            
                    <div style={{display:auth ?"":"none"}}>
                    <AccordionBlock expanded={true} name={"総合"} content={generalInfo}  />
                    <AccordionBlock name={"ロコミ"} content={feedbackContent}  />
                    <AccordionBlock name={"メニュー"} content={examplesContent}  />           
                    <AccordionBlock name={"サービス"} content={pricesContent}  />               
                    <AccordionBlock name={"よくある質問"} content={faqContent}  />               
                    </div>
                </div>
            </div>
        </>
    )
}