import { TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import * as Constants from "../config";
import { CardDADContainer } from "./card_container";

export default function Faq(){

    const data = [{
        id:0,
        question: "",
        answer:""
    }];

    const [dataState, setDataState] = useState(data);
    const [changed, setChanged] = useState(false);
    const [dataStateHtml, setdataStateHtml] = useState(data);

    const deleteData= (id) =>{
        const newData = dataState.filter((obj) => obj.id !== id);
        setDataState(newData);
        setHtmlForOrder(newData);   
    };
    
    const addData = () =>{
        let newid = 0;
        if(dataState !== undefined && dataState.length > 0)
            newid =  Math.max(...dataState.map(o => o.id))+ 1;
        const newElement = {id: newid, question:"",answer:""};
        var data = [...dataState, newElement];
        setDataState(data);
        setHtmlForOrder(data);   
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        var data = ChangeArray(dataState, id, name, value);
        setDataState(data); 
        setHtmlForOrder(data);   
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.questionsPath)
        .then((response) => response.json())
        .then((data) => {
           setDataState(data);
           setHtmlForOrder(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
    function setHtmlForOrder(data){
        var newArr = [];
        for(var a in data){
            var item = data[a];
            var newObj = {};
            newObj.id = data[a].id;
            newObj.mainid = data[a].id;
            newObj.html = <>
            <div className="order-card-content">
                <div>{data[a].question}</div>
            </div>
            </>;
            newArr.push(newObj);
        }        
        setdataStateHtml(newArr);
        setChanged(!changed);    
    }

    function setOrderOfContent(data){
        var newArr = [];
        if(data == null || data == undefined || data.length == 0)
            return;
        if(dataState == null || dataState == undefined || dataState.length == 0)
            return;        
        console.log(data);
        for(var a in data){
            var newObj = {};
            newObj = dataState.find(x=>x.id==data[a])
            newArr.push(newObj);
        }
        setDataState(newArr);
    }

    const saveData = () => {
        const body = Constants.requestBody(dataState);
        fetch(Constants.apiurl + Constants.questionsPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => setDataState(data))
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const inputsHtml = (id,question,answer) => {
        return(
            <>
                <div className="input-block">
                    <div className="input-title">質問:</div>
                    <input id={id} onChange={onChangeValue} name="question" label="Question" variant="outlined" value={question} />
                </div>
                <div className="input-block">
                    <div className="input-title">答え:</div>
                    <input id={id}  onChange={onChangeValue} name="answer" label="Answer" variant="outlined" value={answer} />
                </div>
            </>
        )
    };

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + "faq"} child={inputsHtml(item.id,item.question,item.answer)} id={item.id} handleDelete={deleteData} />
        )

    return(
        <>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData}/>
            </div>
            {dataHtml}
            <div>
                <CardDADContainer key={"cardcontainer" + changed} setOrderOfContent={setOrderOfContent} data={dataStateHtml}/>
            </div>
        </>
    )
}