import { useState } from "react";
import { act } from "react-dom/test-utils";

export default function QuestionAcc(props){

    const [active, setActive] = useState(false);

    const questionAcc = {
        backgroundColor:"#e1f0f7",
        marginBottom:"10px",
        paddingRight:"20px",
        width: props.innerContentWidth -60,
        fontSize: props.contentWidth/30,
        height: active ? "auto" : "auto",
        paddingLeft:"20px",
        paddingBottom:"5px",
        paddingTop:"10px",
        borderRadius:"10px",
        transition:"height 0.3s",
        //overflow:"hidden",
        position:"relative"     
    };

    const icon = {
        position:"absolute",
        top:"10px",
        right:"10px",
        cursor:"pointer",
        width:"20px"
    };

    return(
        <>
            <div onClick={() => setActive(!active)} className="defaultFont" style={questionAcc}>
                <div style={{paddingBottom:"10px"}}>  Q. {props.q} </div>
                <div style={{display:active?"":"none"}}>  A. {props.a} </div>
                <img src={active ? "arrow.svg" : "arrow2.svg"} style={icon}/>
            </div>

        </>
    )
}