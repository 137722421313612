import { HighlightOffOutlined } from "@mui/icons-material";

export default function Card(props){
    
    const cardStyle = {
        backgroundColor: !!(props.backgroundColor) ? props.backgroundColor : "rgb(235 249 255)",
        padding:"20px",
        paddingTop:"40px",
        margin:"10px",
        border:"solid 1px #b1b1b1",
        borderRadius:"4px",
        position:"relative",
        display:"inline-block"
    };

    const deleteIcon = {
        position:"absolute",
        top:"5px",
        right:"5px",
        cursor:"pointer",
        display: props.handleDelete === undefined ? "none" : ""
    };

    const inputsStyle = {
        minWidth:"200px",
        display: "inline-block"
    };    

    const iconStyle={
        fontSize:"40px"
    };

    return(
        <div style={cardStyle}>
            <div style={inputsStyle}>
                {props.child}
            </div>
            <div style={deleteIcon} id={props.id}  onClick={() => props.handleDelete(props.id)}>
                <HighlightOffOutlined style={iconStyle}/>
            </div>
        </div>
    )
}