import { Button } from "@mui/material";

export default function ChangesButtons(props){

    const style={
        margin:"5px"
    };

    const addActionStyle={        
        margin:"5px",
        display: props.addAction == undefined ? "none" : ""
    };

    return(
        <>
            <Button style={addActionStyle} onClick={props.addAction} variant="contained" color="secondary">追加</Button>
            <Button style={style} onClick={props.saveAction} variant="contained" color="success">保存</Button>
            <Button style={style} onClick={props.revertAction} variant="outlined" color="error">元に戻す</Button>
        </>
    )
}