import { TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import {Container} from "./container";

export default function GeneralInfo(){

    const data = {
        imageHead : "",
        imageBody: "",
        popupimage: "",        
        promotitle: "",
        promotext: "",
        companytitle: "",
        companytext: "",
        area:"",
        phone: "",
        line: ""

    };
    const [dataState, setDataState] = useState(data);

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
        
    const [selectedImage, setSelectedImage] = useState("");

    const onChangeValue = (e) => {        
        const { name, value } = e.target;
        setDataState({...dataState, [name]: value});
    };

    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var e = {
                target:{
                    name:uploadPropertyState,
                    value:filename
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const selectImage = (e) => {
        const { name, value } = e.target;
        setSelectedImage(value);
        setUploadPropertyState(name);
        setUploaderActiveState(true);
    };


    const getData = () => {
        fetch(Constants.apiurl + Constants.generalPath)
        .then((response) => response.json())
        .then((data) => {
           setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
    
    const saveData = () => {
        const body = Constants.requestBody(dataState);
        fetch(Constants.apiurl + Constants.generalPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => setDataState(data))
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const firstblock = (
            <>                
                <div className="card-title">画像</div>
                <div className="input-block">
                    <div className="input-title">ヘッダー画像:</div>
                    <input title={dataState.imageHead} style={{"cursor":"pointer"}} onClick={selectImage} name="imageHead" readOnly value={dataState.imageHead}/>
                </div>
                <div className="input-block">
                    <div className="input-title">メイン画像:</div>
                    <input title={dataState.imageBody} style={{"cursor":"pointer"}} onClick={selectImage} name="imageBody" readOnly value={dataState.imageBody}/>
                </div>
                <div className="input-block">
                    <div className="input-title">ポップアップ画像:</div>
                    <input title={dataState.popupimage} style={{"cursor":"pointer"}} onClick={selectImage} name="popupimage" readOnly value={dataState.popupimage}/>
                 </div>
            </>
        );

    const secondblock = (
            <>
                <div className="card-title">キャンペーン</div>
                <div className="input-block">
                    <div className="input-title">タイトル:</div>
                    <input id="promotitle" onChange={onChangeValue} name="promotitle" label="promotitle" variant="outlined" value={dataState.promotitle} />
                </div>
                <div className="input-block">
                    <div className="input-title">本文:</div>
                    <textarea id="promotext" onChange={onChangeValue} name="promotext" label="promotext" variant="outlined" value={dataState.promotext} />
                </div>
            </>
        );

    const thirdblock = (
            <>
                <div className="card-title">会社理念</div>
                <div className="input-block">
                    <div className="input-title">タイトル:</div>
                    <input id="companytitle" onChange={onChangeValue} name="companytitle" label="companytitle" variant="outlined" value={dataState.companytitle} />
                </div>
                <div className="input-block">
                    <div className="input-title">本文:</div>
                    <textarea id="companytext" onChange={onChangeValue} name="companytext" label="companytext" variant="outlined" value={dataState.companytext} />
                </div>
                <div className="input-block">
                    <div className="input-title">対応エリア 1:</div>
                    <textarea id="area1" onChange={onChangeValue} name="area1" label="area1" variant="outlined" value={dataState.area1} />
                </div>
                <div className="input-block">
                    <div className="input-title">対応エリア 2:</div>
                    <textarea id="area2" onChange={onChangeValue} name="area2" label="area2" variant="outlined" value={dataState.area2} />
                </div>
                <div className="input-block">
                    <div className="input-title">googleformフォームHTML:</div>
                    <textarea id="googleform" onChange={onChangeValue} name="googleform" label="googleform" variant="outlined" value={dataState.googleform} />
                </div>
                <div className="input-block">
                    <div className="input-title">電話番号:</div>
                    <input id="phone" onChange={onChangeValue} name="phone" label="phone" variant="outlined" value={dataState.phone} />
                </div>
                <div className="input-block">
                    <div className="input-title">LINE:</div>
                    <input id="line" onChange={onChangeValue} name="line" label="line" variant="outlined" value={dataState.line} />
                </div>
            </>
        );
        function setOrderOfContent(values){
            setDataState({...dataState, ["contentOrder"]: values});
        }
         
    return(
        <>
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData}/>
            </div>
            <div className="cardscontainer">
                <Card child={firstblock}/>
                <Card child={secondblock}/>
                <Card child={thirdblock}/>
            </div>
            <div className="draganddrop">
                <div className="title">順番</div>
                <Container key={dataState.companytitle} order={dataState.contentOrder} setOrderOfContent={setOrderOfContent}/>
            </div>
        </>
    )
}